<template>
  <v-app class="status-page">
    <div v-if="isLoading" class="loader">
      <div class="logo">
        <img
          src="@/assets/images/logo-circle.svg"
          height="35"
          style="background: #2b2f44; border-radius: 50%;"
        />
      </div>
    </div>
    <div v-if="isShowHeader" class="navbar-wrapper">
      <div class="logo">
        <img src="@/assets/images/logo.png" alt />
      </div>
      <div class="text-h5 mt-4 mb-2 text-center">Driftinformation</div>
      <div class="menu-items">
        <v-btn color="primary" to="/signin">{{ $t("userAuth.login") }}</v-btn>
      </div>
    </div>
    <div class="wrapper" :class="isShowHeader ? '' : 'no-header'">
      <v-tabs
        v-model="tab"
        background-color="blue-grey darken-1"
        color="orange darken-2"
        active-class="white--text"
        grow
        dark
      >
        <v-tabs-slider />
        <v-tab key="error" color="red">Aktuell driftinfo</v-tab>

        <v-tab key="plan">Planerade arbeten</v-tab>

        <v-tab
          v-for="item in tabItems"
          :key="item"
        >
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="error">
          <error-status :reports="errorReports" :errorReportsSuccess="errorReportsSuccess" :zitiusSuccess="zitiusSuccess" />
        </v-tab-item>

        <v-tab-item key="plan">
          <planned-info :reports="plannedReports" />
        </v-tab-item>

        <v-tab-item
          v-for="item in tabItems"
          :key="item"
        >
          <v-timeline
            v-if="ipOnlyReportData[item].length > 0"
            align-top
            dense
          >
            <v-timeline-item
              v-for="(report, index) in ipOnlyReportData[item]"
              :key="index"
              color="success"
              fill-dot
            >
              <v-row>
                <v-col cols="12" class="pt-1 pb-0">
                  <strong>{{ report.city }}</strong>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <div v-html="report.description" />
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'
import moment from 'moment-timezone'

import util from '@/utils'

import ErrorStatus from './ErrorStatus'
import PlannedInfo from './PlannedInfo'

export default {
  metaInfo: {
    title: 'Reports',
  },

  components: {
    ErrorStatus,
    PlannedInfo,
  },

  data() {
    return {
      isLoading: false,
      tab: 'error',
      plannedReports: [],
      errorReports: [],
      errorReportsSuccess: true,
      zitiusSuccess: true,
      isShowHeader: true,
      tabItems: [],
      ipOnlyReportData: [],
    };
  },

  mounted() {
    if (this.$route.name === 'EmbedStatus') {
      this.isShowHeader = false
    }

    this.loadReportData()
  },

  methods: {
    ...mapActions({
      fetchReportData: 'fetchReportData',
      fetchIPOnlyReportData: 'fetchIPOnlyReportData',
    }),

    getStatusColor(status) {
      if (status === 'TroubleShooting') {
        return 'error'
      } else if (status === 'Done') {
        return 'success'
      } else if (status === 'InProgress') {
        return 'purple'
      }

      return 'warning'
    },

    getStatusText(status) {
      if (status === 'TroubleShooting') {
        return 'Felsökning pågår'
      } else if (status === 'InProgress') {
        return 'Felavhjälpning pågår'
      } else if (status === 'Done') {
        return 'Felet avhjälpt'
      }

      return ''
    },

    async loadReportData() {
      this.isLoading = true;
      try {
        const response = await this.fetchReportData()
        this.plannedReports = _.orderBy(response.plannedReports, ['startTime']).map(item => {
          const isTelenor = item.operatorName.includes('Telenor')
          return {
            ...item,
            operatorName: isTelenor ? 'OpenUniverse' : item.operatorName,
            description: isTelenor
              ? this.formatTelenorDescription(item.description, 'plan')
              : item.description,
          }
        });

        const errorReports = response.errorReports.map(
          item => {
            const isTelenor = item.operatorName.includes('Telenor')
            const title = isTelenor ? 'Driftstörning' : item.title
            return {
              ...item,
              title,
              operatorName: isTelenor ? 'OpenUniverse' : item.operatorName,
              description: isTelenor
                ? this.formatTelenorDescription(item.description, 'error')
                : item.description,
              color: this.getStatusColor(item.status),
              statusText: this.getStatusText(item.status),
              type: 'normal',
            };
          }
        );

        const zitiusErrorReports = response.zitiusErrorReports.map(item => {
          return {
            ...item,
            color: 'warning',
            regions: (item.regions || []).join(", ")
          }
        })

        this.errorReportsSuccess = response.errorReportsSuccess
        this.zitiusSuccess = response.zitiusErrorReportsSuccess

        const ipOnlyResponse = await this.fetchIPOnlyReportData()

        this.ipOnlyReportData = _.groupBy(ipOnlyResponse, (item) => item.category)
        const categories = []
        for (const category of Object.keys(this.ipOnlyReportData)) {
          if (category === 'Pågående driftstörningar') {
            continue
          }

          categories.push(category)
        }

        this.tabItems = categories

        const ipOnlyErrors = this.formatIpOnlyErrorData(this.ipOnlyReportData['Pågående driftstörningar'])
        this.errorReports = _.orderBy([...errorReports, ...zitiusErrorReports, ...ipOnlyErrors], ['downTime'], ['desc'])
      } catch (error) {
        // No need to do anything
      }
      this.isLoading = false
    },

    formatTelenorDescription(description, type) {
      if (!description) {
        return ''
      }

      const serviceList = description.split('\n')
      const regex = /�/i;

      let formattedDescription = type === 'error'
        ? 'Vi har förnärvarande en driftstörning.\nDetta innebär att vår bredbands-, tv- och telefonitjänst är otillgänglig för kunder på berörda postnummer/adresser.'
        : serviceList[0].replace(regex, 'å')

      formattedDescription += '\n\npostnummer/adresser:\n'

      for (const service of serviceList) {
        if (!service) {
          continue
        }

        if (!service.includes('Address:')) {
          continue
        }

        const address = service.split('Address:')
        formattedDescription += address[1].trim() + '\n'
      }

      return formattedDescription
    },

    formatIpOnlyErrorData(data) {
      const ipOnlyErrorData = []
      let index = 0

      for (const item of data) {
        const { downTime, activeTime, expectedRepairTime } = this.getDownAndActiveTimes(item.description)
        const status = activeTime ? 'Done' : (expectedRepairTime ? 'InProgress' : 'TroubleShooting')
        let description = item.description.replaceAll('<p></p>', '')

        if (description.endsWith('\n')) {
          description = description.slice(0, -2)
        }

        ipOnlyErrorData.push({
          operatorName: 'IP-Only',
          title: 'Driftstörning',
          description,
          downTime,
          status,
          expectedToWork: activeTime || expectedRepairTime,
          color: activeTime ? 'success' : (expectedRepairTime ? 'purple' : 'error'),
          statusText: this.getStatusText(status),
          type: 'normal',
        })

        index++
      }

      return ipOnlyErrorData
    },

    getDownTime(content) {
      const regex = content.includes('Occured') ? /Occured:(.*)/ : /Occurred:(.*)/
      const match = regex.exec(content);

      if (!match || match.length <= 1) {
        return ''
      }

      const downTime = match[1].trim().slice(0, -9) // 2023-09-19 15:58

      return downTime && moment.tz(downTime, 'Europe/Berlin').format('YYYY-MM-DDTHH:mm:ss')
    },

    getActiveTime(content) {
      const lines = content.split('</p>')
      const text = lines.find(item => item.includes('Service(s) restored'))

      const regex = /Service\(s\) restored:(.*)/
      const match = regex.exec(text);

      if (!match || match.length <= 1) {
        return ''
      }

      const activeTime = match[1].trim().slice(0, -9) // 2023-09-19 15:58

      return activeTime && moment.tz(activeTime, 'Europe/Berlin').format('YYYY-MM-DDTHH:mm:ss')
    },

    getExpectedRepairTime(content) {
      const lines = content.split('</p>')
      const text = lines.find(item => item.includes('Estimated Time of Repair'))

      const regex = /Estimated Time of Repair:(.*)/
      const match = regex.exec(text);

      if (!match || match.length <= 1) {
        return ''
      }

      if (match[1].includes('To be announced')) {
        return match[1].trim()
      }

      const expectedRepairTime = match[1].trim().slice(0, -9) // 2023-09-19 15:58

      return expectedRepairTime && moment.tz(expectedRepairTime, 'Europe/Berlin').format('YYYY-MM-DDTHH:mm:ss')
    },

    getDownAndActiveTimes(content) {
      const lines = content.split('<br>')
      const availableLines = lines.filter(item => item.includes('Occured') || item.includes('Occurred') || item.includes('Service(s) restored')|| item.includes('Estimated Time of Repair'))

      let downTime = ''
      let activeTime = ''
      let expectedRepairTime = ''

      for (const line of availableLines) {
        if (line.includes('Occured') || line.includes('Occurred')) {
          downTime = this.getDownTime(line)
        } else if (line.includes('Estimated Time of Repair')) {
          expectedRepairTime = this.getExpectedRepairTime(line)
        } else {
          activeTime = this.getActiveTime(line)
        }
      }

      return {
        downTime,
        activeTime,
        expectedRepairTime,
      }
    },
  }
}
</script>

<style lang="scss">
.status-page {
  .navbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    background: #fff;
    position: fixed;
    padding: 10px 100px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    z-index: 9999;

    .logo img {
      height: 60px;
    }

    .menu-item {
      min-width: 100px;
      text-align: center;
    }
  }

  .wrapper {
    width: 1200px;
    margin: 80px auto 0;

    &.no-header {
      margin-top: 30px;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }
}
</style>
